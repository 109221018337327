<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import EssayTable from '@/components/tables/essays-table.vue';
import TrackingTable from '@/components/tables/trackings-table.vue';
import ContinuitiesTable from "@/components/tables/continuities-table.vue";
import AccesionCompanyTable from "@/components/tables/accesion-company-table.vue";
import AccesionCenterTable from "@/components/tables/accesion-center-table.vue";
import api from '@/helpers/api-rest/api.js';
import format from 'date-fns/format';
import TargetsModal from "../../../components/modals/targets-modal.vue";
import ActionsModal from "../../../components/modals/actions-modal.vue";
import PartnersModal from "../../../components/modals/partners-modal.vue";
import EssayActionModal from '@/components/modals/essay-actions-modal.vue';
import ResignationModal from "@/components/modals/resignation-modal.vue";
import store from '@/state/store';
import qs from 'qs';
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { errorMessages } from "@/helpers/validations/errorMessages";

/**
 * FAQs component
 */
export default {
  props: ['id', 'essayId'],
  // page: {
  //   title: "Proyecto X",
  //   meta: [{ name: "description" }]
  // },
    components: { 
        Layout, 
        PageHeader, 
        TargetsModal, 
        EssayActionModal, 
        ActionsModal, 
        PartnersModal, 
        ResignationModal, 
        TrackingTable, 
        ContinuitiesTable,
        AccesionCompanyTable,
        AccesionCenterTable
    },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Convocatorias",
          href: "/projects"
        },
        {
          text: "",
          active: true
        }
      ],
      essay: {},
      createdBy: null,
      dataUser: [],
      isContentVisible: false,
      loading: false,
    //   isCollapseOpen: false,
      collapseOpen: [],
      targetModalId: 'ModalTargets',
      actionModalId: 'ModalActions',
      partnerModalId: 'ModalPartners',
      resignationModalId: 'ModalResignation',
      accessionCompanyModalId: 'ModalAccessionCompany',
      resignationEdit: undefined,
      accesionCompanyEdit: undefined,
      targetView: undefined,
      actionView: undefined,
      partnerView: undefined,
      parentPartner: undefined,
      essayActionModalId: 'ModalActionEssay',
      idEssay: null,
      actionType: '',
      action: {},
      showStrategicLines: false,
      showTargets: false,
      showCoordinator: false,
      showPartners: false,
      showActions: false,
      centerCoordinatorId: undefined,
      accesionCompanies: [],
      loadingAnexoIX: false,
      imageAnexoIX: null,
      selectOptionsEssayStatus: [],
      editingStatus: false,
      stepEssay: null,
      finish_project: null,
      errorMessages,
      fileName: 'Selecciona una imagen'
    };
  },
    validations: {
        essay: {
            objetives_assessment: {required},
            objetives_not_achieved: {required},
            changes_project: {required },
            global_assessment: { required },
            project_achievements: {required },
            indicators_assessment: {required},
            centers_incidents: {required},
            resources_description: {required},
            // image_final: {required}
        }
    },
  methods: {
    showElement(roleId = [], show = true, showRechazado = true, showRenuncia = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showRechazado && showRenuncia) {
        return true;
      }
      return false;
    },
    loadSelectOptionsCenterType(){
        const apiEndpoint = `/api/v1/essay-statuses`;
        api.get({url:apiEndpoint})
        .then(response => {
            this.selectOptionsEssayStatus = response?.data?.data;
        })
        .catch(error => {
            console.error("Error al cargar los tipos de estados:", error);
        });
    },
    loadDataFromApi(){
        this.$v.$reset();
        this.loading = true;
        const apiEndpoint = `/api/v1/essays/${this.essayId}`;
        api.get({url:apiEndpoint})
        .then(response => {
            this.essay = response?.data?.data;
            this.title = this.essay.name;
            this.items[1].text = this.essay.name;
            this.essay.essay_center_partner.map(center_partner => {
                center_partner.showDetail = false;
                return center_partner;
            });
            this.essay.formattedCreatedAt = format(new Date(this.essay.created_at), 'dd/MM/yyyy');
            this.loadDataUser(this.essay.created_by_id);
            this.centerCoordinatorId = this.essay.essay_coordinator.center_id;
            if (this.essay.resignation) {
                this.essay.resignation.created_at = format(new Date(this.essay?.resignation?.created_at), 'dd/MM/yyyy');
            }
            this.essay.accession_company_partner.map(accession_company => {
                this.accesionCompanies = accession_company;
                return accession_company;
            });
            this.loadSelectOptionsCenterType();
            this.loading = false;
        })
        .catch(error => {
            this.loading = false;
            console.error("Error loading data:", error);
        });
    },
    loadDataUser(id){
      const apiEndpoint = `/api/v1/users/${id}`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.dataUser = response?.data?.data;
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    downloadFile(filePath){
      const baseUrl = process.env.VUE_APP_API_HOST;
      const url = `${baseUrl}/download?file=${filePath}`;
      window.open(url, '_self');
    },
    toggle(element) {
      element.showDetail = !element.showDetail;
    },
    toggleContent() {
        this.isContentVisible = !this.isContentVisible;
    },
    toggleCollapse(index) {
      this.$set(this.collapseOpen, index, !this.collapseOpen[index]);
    },
    viewTarget(target) {
        this.targetView = {
            ...target,
            essay: this.essay
        };
      this.$bvModal.show(this.targetModalId);
    },
    viewAction(action){
        this.actionView = action;
        this.$bvModal.show(this.actionModalId);
    },
    addAction(){
        this.actionView = {
            id: null,
            name: '',
            descriptions: '',
            init_date: '',
            end_date: '',
            responsables: '',
            risks: '',
            contingency_plan: '',
            education_activities: [], 
            economic_forecats: [], 
            essay_id: this.essayId
        };
        this.$bvModal.show(this.actionModalId);
    },
    editResignation(resignation) {
      this.resignationEdit = resignation;
      this.$bvModal.show(this.resignationModalId);
    },
    addResignation(){
      this.resignationEdit = {id: null, document_name: null};
      this.$bvModal.show(this.resignationModalId);
    },
    addAccessionCompany(){
      this.accesionCompanyEdit = {id: null, document_name: null};
      this.$bvModal.show(this.accessionCompanyModalId);
    },
    downloadFiles(fileName, folderID){
        const params = {
            'fileName': fileName,
            'folderID': folderID,
        };

        const queryString = qs.stringify(params);
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/download-drive?${queryString}`;
        window.open(url, '_blank');

    //   const baseUrl = process.env.VUE_APP_API_HOST;
    //   const url = `${baseUrl}/download?file=${filePath}`;
    //   window.open(url, '_self');
    },
    viewPartner(center, parent){
        this.partnerView = center;
        this.parentPartner = parent;
        this.$bvModal.show(this.partnerModalId);
    },
    showModalAction(actionType, essay){
        this.action = {
            description: '',
            essay_id: null
        }
        this.actionType = actionType;
        this.idEssay = essay;
        this.$bvModal.show(this.essayActionModalId);
    },
    successAction(){
        this.loadDataFromApi();
        console.log("Success Action");
        
    },
    successResignation(){
        this.loadDataFromApi();
        console.log("Success Resignation");
    },
    successAccessionCompany(){
        this.loadDataFromApi();
        console.log("Success Accession Company");
    },
    successAddAction(){
        this.loadDataFromApi();
        console.log("Success Add Action");
    },
    successUpdateTarget(){
        this.loadDataFromApi();
        console.log("Success Update Target");

    },
    finishAssesment(){
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.warningMsgValidation();
            return;
        }

        this.loading = true;
        this.essay.image_final = this.file?.name;
        this.uploadFile(this.file, 'images')
        .then((response) => {
            const file = response?.data?.data;
            this.essay.image_final_path = file.path;
            const apiEndpoint = `/api/v1/essay/${this.essay.id}/update-assessment`;
            const data = {
                objetives_assessment: this.essay.objetives_assessment,
                objetives_not_achieved: this.essay.objetives_not_achieved,
                changes_project: this.essay.changes_project,
                global_assessment: this.essay.global_assessment,
                project_achievements: this.essay.project_achievements,
                indicators_assessment: this.essay.indicators_assessment,
                centers_incidents: this.essay.centers_incidents,
                resources_description: this.essay.resources_description,
                final_interview: this.essay.final_interview,
                image_final: this.essay.image_final,
                image_final_path: this.essay.image_final_path,
                finalising_project: 1,
                finish_action: 'finish'
            };
            api.patch({url:apiEndpoint, data})
            .then(() => {
                this.loading = false;
                Swal.fire({
                    icon: 'success',
                    html: "Se ha solicitado la finalización del proyecto. Recibirá un correo con la confirmación para generar el Anexo IX.",
                    // timer: 2500,
                    showConfirmButton: true,
                });
                this.loadDataFromApi();  
            })
            .catch(error => {
                this.loading = false;
                console.error("Error loading data:", error);
            });
        })
        .catch((error) => {
            console.error("Error al cargar el archivo:", error);
        });
        
    },
    saveAssesment(){
        this.loading = true;
  
        if (this.file) {
            // Si hay archivo, se asigna el nombre y se sube la imagen
            this.essay.image_final = this.file.name;
            this.uploadFile(this.file, 'images')
            .then((response) => {
                const file = response?.data?.data;
                this.essay.image_final_path = file.path;
                // Una vez subida la imagen, se procede a guardar el resto del formulario
                this.updateAssessment();
            })
            .catch((error) => {
                this.loading = false;
                console.error("Error al cargar el archivo:", error);
            });
        } else {
            // Si no hay archivo, se asignan valores nulos (o lo que corresponda) y se guarda el formulario
            this.essay.image_final = null;
            this.essay.image_final_path = null;
            this.updateAssessment();
        }
    },
    updateAssessment(){
        const apiEndpoint = `/api/v1/essay/${this.essay.id}/update-assessment`;
        const data = {
            objetives_assessment: this.essay.objetives_assessment,
            objetives_not_achieved: this.essay.objetives_not_achieved,
            changes_project: this.essay.changes_project,
            global_assessment: this.essay.global_assessment,
            project_achievements: this.essay.project_achievements,
            indicators_assessment: this.essay.indicators_assessment,
            centers_incidents: this.essay.centers_incidents,
            resources_description: this.essay.resources_description,
            final_interview: this.essay.final_interview,
            image_final: this.essay.image_final,
            image_final_path: this.essay.image_final_path,
            finalising_project: 0,
            finish_action: 'save'
        };

        api.patch({ url: apiEndpoint, data })
        .then(() => {
            this.loading = false;
            Swal.fire({
                icon: 'success',
                html: "Se ha guardado la finalización del proyecto.",
                showConfirmButton: true,
            });
            this.loadDataFromApi();
        })
        .catch((error) => {
            this.loading = false;
            console.error("Error loading data:", error);
        });
    },
    generateAnexoIX(){
        this.loadingAnexoIX = true;
        const apiEndpoint = `/api/v1/essay/${this.essay.id}/generate-anexo9`;
        api.post({url:apiEndpoint})
            //axios.post(apiEndpoint, this.user)
            .then((response) => {
                this.loadingAnexoIX = false;
                const newFile = response?.data?.data;
                this.successmsg(newFile.url_anexo_final);
            })
            .catch((error) => {
                this.loadingAnexoIX = false;
                console.error("Error al crear el essay:", error);
            });
    },
    successmsg(url) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: "!Buen trabajo!",
        html: `
          <div style="position: relative;">
            <iframe src="${url}" frameborder="0" style="height: 70vh; width: 100%;"></iframe>
          </div>
        `,
        icon: "success",
        width: 1000,
        padding: 20,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: "Aceptar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.close();
        }
      });
    },
    swalSendMailForm() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success mr-4",
          cancelButton: "btn btn-light"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        text: "¿Deseas enviar la encuesta?",
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Enviar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendMailForm();
        }
      });
    },
    sendMailForm(){
        this.loading = true;
        const apiEndpoint = `/api/v1/essay/${this.essay.id}/send-email-form`;
        api.post({url:apiEndpoint})
        .then(response => {
            console.log(response);
            this.loading = false;
        })
        .catch(error => {
            this.loading = false;
            console.error("Error al mandar correo:", error);
        });
    },
    onFileChange(event){
        this.file = event.target.files[0];
        this.fileName = this.file.name;
        if (this.file && this.file.type.startsWith("image/")) {
            this.imageAnexoIX = URL.createObjectURL(this.file);
            this.essay.image_final = null;
        }
        
    },
    uploadFile(file, folder){
        const formData = new FormData();
        formData.set("file", file);
        formData.append("path", folder);
        const config = { headers: { "content-type": "multipart/form-data" } };

        const apiEndpoint = `/api/v1/upload-file`;
        return api.post({ url: apiEndpoint, data: formData, config })
    },
    changeStatus(){
        const selectedStatusId = this.essay.essay_status_id;
        this.stepEssay = selectedStatusId == 1 ? this.stepEssay = 2 : this.stepEssay = 3;
        this.finish_project = selectedStatusId == 5 ? this.finish_project = 0 : this.finish_project = 1;
        const apiEndpoint = `/api/v1/essay/${this.essay.id}/change-status`;
        const data = {essay_status_id: selectedStatusId, current_step: this.stepEssay, finalising_project: this.finish_project};
        api.patch({url:apiEndpoint, data})
            .then(() => {
                this.editingStatus = false;
                this.loadDataFromApi(); 
            })
            .catch((error) => {
                this.loadingAnexoIX = false;
                console.error("Error al actualizar el estado del essay:", error);
            });       
    },

    exportUsers(){
        const token = localStorage.getItem('authToken');
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/export-data/${token}/${this.essayId}`;
        window.open(url, '_blank');
    },

    async handleButtonClick(event) {
        
        // Prevenir cualquier comportamiento predeterminado (por seguridad adicional)
        if (event) {
            event.preventDefault();
            event.stopPropagation(); // Detiene propagación a otros elementos
        }

        this.loading = true; // Activar estado de carga
        setTimeout(() => {
            this.loading = false;
        }, 5000); // Simulación de 5 segundos

    //   try {
    //     await this.downloadFiles(essay?.document_name, essay?.folder_documents_id); // Acción asociada al botón
    //   } catch (error) {
    //     console.error("Error en la descarga", error);
    //   } finally {
    //     this.loading = false; // Desactivar estado de carga
    //   }
    },

    warningMsgValidation() {
      Swal.fire({
        icon: 'error',
        html: "Hay campos requeridos sin completar",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  },
  mounted() {
    this.loadDataFromApi();    
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <div class="text-center text-primary qlk-spinner" v-if="loading">
        <span aria-hidden="true" class="align-middle spinner-border"></span>
    </div> -->
    <EssayActionModal :id="essayActionModalId" :actionType="actionType" :essayId="idEssay" :action="action" @success="successAction"/>
    <TargetsModal :id="targetModalId" :target="targetView" @success="successUpdateTarget"/>
    <ActionsModal :id="actionModalId" :action="actionView" @success="successAddAction"/>
    <ResignationModal :id="resignationModalId" :resignation="resignationEdit" :essayId="essayId" :centerId="centerCoordinatorId" @success="successResignation"/>
    <PartnersModal :id="partnerModalId" :partner="partnerView" :parentPartner="parentPartner" />

    <b-skeleton-wrapper :loading="loading">
        <template #loading>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <div>
                                        <div class="d-flex align-items-center mb-4">
                                            <b-skeleton width="65%"></b-skeleton>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <b-skeleton width="55%"></b-skeleton>
                                                <b-skeleton width="55%"></b-skeleton>
                                                <b-skeleton width="55%"></b-skeleton>
                                            </div>
                                            <div class="col-lg-4">
                                                <b-skeleton width="85%"></b-skeleton>
                                                <div class="row">
                                                    <div class="col-lg-8">
                                                        <b-skeleton width="55%"></b-skeleton>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <b-skeleton width="55%"></b-skeleton>
                                                    </div>
                                                </div>
                                                <div>
                                                    <b-skeleton width="55%"></b-skeleton>
                                                </div>
                                                <div>
                                                    <b-skeleton width="55%"></b-skeleton>
                                                </div>                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="row">
          <div class="col-lg-12">
            <!-- Título y botones -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex align-items-center">
                                <h4 class="mb-0">
                                    {{ essay?.name }}
                                </h4>
                                <b-badge pill :variant="essay?.essay_status_id === 3 ? 'warning' : (essay?.essay_status_id === 4 || essay?.essay_status_id === 6 ? 'danger' : (essay?.essay_status_id === 7 ? 'success' : 'info'))" class="ml-3">
                                    {{ essay?.essay_status?.name }}
                                </b-badge>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div v-if="showElement([1], essay?.essay_status_id == 2)" class="d-flex justify-content-center">
                                <b-button @click="showModalAction('approve', essay?.id)" variant="success" class="mr-3 w-md">Aprobar</b-button>
                                <b-button @click="showModalAction('return', essay?.id)" variant="primary" class="mr-3 w-md">Devolución</b-button>
                                <b-button @click="showModalAction('reject', essay?.id)" variant="danger" class="w-md">Rechazar</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
                <!-- Tab información -->
                <b-tab title="Información" lazy>
                    <!-- Información general -->
                    <div class="card">
                        <div class="card-body">
                            <div class="row mt-4">
                            <div class="col-lg-12">
                                <div>
                                    <div class="row">
                                        <div class="col-lg-7">
                                            <h5 class="mb-3">Información general</h5>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <h6>Fecha de creación</h6>
                                                    <p class="text-muted">
                                                        {{ essay?.formattedCreatedAt }}
                                                    </p>
                                                </div>
                                                <div class="col-lg-6">
                                                    <h6>Estado</h6>
                                                    <div class="d-flex align-items-center" v-if="!editingStatus">
                                                        <b-badge pill :variant="essay?.essay_status_id === 3 ? 'warning' : (essay?.essay_status_id === 4 || essay?.essay_status_id === 6 ? 'danger' : (essay?.essay_status_id === 7 ? 'success' : 'info'))">
                                                            {{ essay?.essay_status?.name }}
                                                        </b-badge>
                                                        <a v-if="showElement([1])" href="javascript:void(0);" class="ml-3 text-secondary" title="Edit" @click="editingStatus = true">
                                                            <i class="mdi mdi-square-edit-outline font-size-18"></i>
                                                        </a>
                                                    </div>
                                                    <div v-else class="d-flex align-items-center">
                                                        <select v-model="essay.essay_status_id" class="form-control" @change="changeStatus">
                                                            <option v-for="option in selectOptionsEssayStatus" :key="option.id" :value="option.id">
                                                                {{ option.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <h6>Descripción</h6>
                                                <p class="text-muted">
                                                    {{ essay?.description }}
                                                </p>
                                            </div>
                                            <div>
                                                <h6>Estado del arte</h6>
                                                <p class="text-muted">
                                                    {{ essay?.arte_status }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-5">
                                            <h5 class="mb-3">Información coordinador</h5>
                                            <div class="row">
                                                <div class="col-lg-8">
                                                    <div>
                                                        <h6>Nombre</h6>
                                                        <p class="text-muted">
                                                            {{ dataUser?.name }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div>
                                                        <h6>DNI</h6>
                                                        <p class="text-muted">
                                                            {{ dataUser?.dni }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <h6>Email</h6>
                                                <p class="text-muted">
                                                    {{ dataUser?.email }}
                                                </p>
                                            </div>
                                            <div>
                                                <h6>Centro</h6>
                                                <p class="text-muted">
                                                    {{ dataUser?.center?.name }}
                                                </p>
                                            </div>                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <!-- Estadísticas -->
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="card">
                                <div class="card-body text-center">
                                    <h4 class="card-title">Número de centros</h4>
                                    <p class="text-muted">
                                        {{ essay?.centers_number }}
                                    </p>
                                </div>
                            </div> 
                        </div>
                        <div class="col-lg-3">
                            <div class="card">
                                <div class="card-body text-center">
                                    <h4 class="card-title">Número de empresas</h4>
                                    <p class="text-muted">
                                        {{ essay?.companies_number }}
                                    </p>
                                </div>
                            </div> 
                        </div>
                        <div class="col-lg-3">
                            <div class="card">
                                <div class="card-body text-center">
                                    <h4 class="card-title">Número de profesorado</h4>
                                    <p class="text-muted">
                                        {{ essay?.professorates_number }}
                                    </p>
                                </div>
                            </div> 
                        </div>
                        <div class="col-lg-3">
                            <div class="card">
                                <div class="card-body text-center">
                                    <h4 class="card-title">Número de estudiantes</h4>
                                    <p class="text-muted">
                                        {{ essay?.students_number }}
                                    </p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <!-- Líneas estratégicas -->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Líneas estratégicas</h5>
                                <!-- <i v-b-toggle.collapse-lines @click="showStrategicLines = !showStrategicLines" class="mr-4" :class="{'fas fa-chevron-down': !showStrategicLines, 'fas fa-chevron-up': showStrategicLines}"></i> -->
                            </div>
                            <div v-for="line in essay?.strategic_lines" :key="line.id">
                                <div v-if="essay?.strategic_lines && essay?.strategic_lines.length > 0">
                                    <ul>
                                    <li>
                                        {{ line.description }}
                                    </li>
                                    </ul> 
                                </div>
                                <div v-else>
                                    No hay resultados
                                </div>
                            </div>
                            <!-- <b-collapse id="collapse-lines" class="mt-1">
                            </b-collapse> -->
                        </div>
                    </div>
                    <!-- Objetivos -->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Objetivos</h5>
                                <i v-b-toggle.collapse-targets @click="showTargets = !showTargets" class="mr-4" :class="{'fas fa-chevron-down': !showTargets, 'fas fa-chevron-up': showTargets}"></i>
                            </div>
                            <b-collapse id="collapse-targets" class="mt-1">
                                <b-card no-body class="mb-1 shadow-none cursor-pointer mt-3" v-for="(target, i) in essay.targets" :key="i" @click="viewTarget(target)">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                    <h6 class="m-0 d-flex justify-content-between align-items-center">
                                        <div class="form-inline p-2">
                                        <span>{{ target.title }}</span>
                                        </div>
                                    </h6>
                                    </b-card-header>
                                </b-card>
                            </b-collapse>
                        </div>
                    </div>
                    <!-- Centro coordinador -->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Centro coordinador</h5>
                                <i v-b-toggle.collapse-coordinator @click="showCoordinator = !showCoordinator" class="mr-4" :class="{'fas fa-chevron-down': !showCoordinator, 'fas fa-chevron-up': showCoordinator}"></i>
                            </div>
                            <b-collapse id="collapse-coordinator" class="mt-1">
                                <div class="row mt-3">
                                    <div class="col-lg-6">
                                        <h6>Centro</h6>
                                        <p class="text-muted">
                                            {{ essay?.essay_coordinator?.center?.name }}
                                        </p>
                                    </div>
                                    <div class="col-lg-6">
                                        <h6>Documento de aprobación del claustro y consejo escolar:</h6>
                                        <b-button variant="info" @click.prevent="downloadFiles(essay?.essay_coordinator?.document_name, essay?.folder_documents_id)">
                                            <i class="fas fa-file-download mr-2"></i> Descargar
                                        </b-button>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="mt-5">Profesorado</h6>
                                        <table class="table table-centered datatable dt-responsive nowrap mt-3" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                            <thead>
                                                <tr>
                                                <th>Nombre</th>
                                                <th>Departamento</th>
                                                <th>DNI</th>
                                                <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-group-divider">
                                                <template v-for="professorate in essay?.essay_coordinator?.essay_professorates">
                                                    <tr :key="professorate?.id">
                                                        <td>{{ professorate?.name }}</td>
                                                        <td>{{ professorate?.department }}</td>
                                                        <td>{{ professorate?.dni }}</td>
                                                        <td>{{ professorate?.email }}</td>
                                                    </tr>
                                                </template>
                                                <tr v-if="!essay?.essay_coordinator?.essay_professorates || essay?.essay_coordinator?.essay_professorates == 0">
                                                    <td colspan="4" class="text-center">
                                                        No hay registros
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="mt-5">Ciclos formativos</h6>
                                        <table class="table table-centered datatable dt-responsive nowrap mt-3" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                            <thead>
                                                <tr>
                                                    <th>Código</th>
                                                    <th>Ciclo formativo</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-group-divider">
                                                <template v-for="course in essay.courses">
                                                    <tr :key="course.id">
                                                        <td>{{ course.mode_code }}</td>
                                                        <td>{{ course.education }} {{ course.mode }}</td>
                                                    </tr>
                                                </template>
                                                <tr v-if="!essay.courses || essay.courses.length == 0">
                                                    <td colspan="2" class="text-center">
                                                        No hay registros
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                    <!-- Centros colaboradores -->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Centros / Empresas colaboradores</h5>
                                <i v-b-toggle.collapse-partners @click="showPartners = !showPartners" class="mr-4" :class="{'fas fa-chevron-down': !showPartners, 'fas fa-chevron-up': showPartners}"></i>
                            </div>
                            <b-collapse id="collapse-partners" class="mt-1">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="mt-3">Centros</h6>
                                        <b-card no-body class="mb-1 shadow-none cursor-pointer mt-3" v-for="(center_partner, i) in essay.essay_center_partner" :key="i" @click="viewPartner(center_partner.center, center_partner)">
                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                <h6 class="m-0 d-flex justify-content-between align-items-center">
                                                    <div class="form-inline p-2">
                                                        <span>{{ center_partner?.center?.name }}</span>
                                                    </div>
                                                </h6>
                                            </b-card-header>
                                        </b-card>
                                    </div>
                                    <div class="col-lg-12">
                                        <h6 class="mt-5">Empresas colaboradoras</h6>
                                        <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                            <thead>
                                                <tr>
                                                <th>Nombre</th>
                                                <th>CIF</th>
                                                <th>Dirección</th>
                                                <th>Responsable</th>
                                                <th>Email</th>
                                                <th>Documento</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-group-divider">
                                                <template v-for="company in essay.partner_companies">
                                                    <tr :key="company.id">
                                                        <td>{{ company.name }}</td>
                                                        <td>{{ company.cif }}</td>
                                                        <td>{{ company.address }}</td>
                                                        <td>{{ company.responsable }}</td>
                                                        <td>{{ company.email }}</td>
                                                        <td>
                                                            <div v-if="company.document_path">
                                                                <b-button size="sm" variant="info" @click.prevent="downloadFile(company?.document_path)">
                                                                    <i class="fas fa-file-download mr-2"></i> Descargar
                                                                </b-button>
                                                                <!-- <a href="javascript:void(0);" class="mr-3 text-secondary" @click.prevent="downloadFile(company.document_path)">
                                                                <i class="mdi mdi-file-download-outline font-size-18"></i>
                                                                </a> -->
                                                            </div>
                                                            <div v-else>No hay documento</div>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <tr v-if="!essay.partner_companies || essay.partner_companies.length == 0">
                                                    <td colspan="6" class="text-center">
                                                        No hay registros
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                    <!-- Planificación de acciones -->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <h5 class="card-title">Planificación de acciones</h5>
                                    <a v-if="showElement([3], essay?.essay_status_id == 5)" href="javascript:void(0);" class="text-success ml-5" @click="addAction()">
                                        <i class="mdi mdi-plus-circle font-size-14"></i> Añadir acción
                                    </a>
                                </div>
                                <i v-b-toggle.collapse-actions @click="showActions = !showActions" class="mr-4" :class="{'fas fa-chevron-down': !showActions, 'fas fa-chevron-up': showActions}"></i>
                            </div>
                            <b-collapse id="collapse-actions" class="mt-1">
                                <b-card no-body class="mb-1 shadow-none cursor-pointer mt-3" v-for="(action, i) in essay.actions" :key="i" @click="viewAction(action)">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                    <h6 class="m-0 d-flex justify-content-between align-items-center">
                                        <div class="form-inline p-2">
                                        <span>{{ action.name }}</span>
                                        </div>
                                    </h6>
                                    </b-card-header>
                                </b-card>
                            </b-collapse>
                        </div>
                    </div>
                    <!-- Informe final Anexo III -->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Informe Final (Anexo III)</h5>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <b-button variant="info" @click.prevent="downloadFiles(essay?.document_name, essay?.folder_documents_id)"><i class="fas fa-file-download mr-2"></i>Descargar</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <!-- Tab seguimiento -->
                <b-tab v-if="showElement([1,3], essay?.essay_status_id != 2)" title="Seguimiento" lazy>
                    <TrackingTable :essayId="essayId" />
                </b-tab>
                <!-- Tab continuidad -->
                <b-tab v-if="showElement([1,3], essay?.essay_status_id != 2)" title="Continuidad" lazy>
                    <ContinuitiesTable :essayId="essayId" :centerId="centerCoordinatorId" />
                </b-tab>
                <!-- Tab adhesiones -->
                <b-tab v-if="showElement([1,3], essay?.essay_status_id != 2)" title="Adhesiones" lazy>
                    <AccesionCompanyTable :essayId="essayId" :centerId="centerCoordinatorId" />
                    <AccesionCenterTable :essayId="essayId" :centerId="centerCoordinatorId" />
                    <!-- <div class="card mt-2">
                        <div class="card-body">
                            <div v-if="accesionCompanies">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h5 class="card-title">Empresas colaboradoras</h5>                                    
                                </div>
                                <div class="row mt-4 p-3">
                                    
                                </div>
                            </div>
                            <div v-else class="row p-3">
                                <div class="col-lg-12 text-center p-4">
                                    <p class="font-italic">No se ha solicitado ninguna adhesión</p>
                                    <b-button @click="addAccessionCompany()" variant="success"><i class="fa fa-plus"></i> Añadir adhesión</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Centros</h5>                                    
                            </div>
                            <div class="row mt-4 p-3">
                                
                            </div>
                        </div>
                    </div> -->
                </b-tab>
                <!-- Tab renuncia -->
                <b-tab v-if="showElement([1,3], essay?.essay_status_id != 2)" title="Renuncia" lazy>
                    <b-button v-if="showElement([3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)" @click="editResignation(essay?.resignation)" class="mb-2" variant="success">Editar renuncia</b-button>
                    <div class="card mt-2">
                        <div class="card-body">
                            <div v-if="essay?.resignation">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h5 class="card-title">Información de renuncia</h5>                                    
                                </div>
                                <div class="row mt-4 p-3">
                                    <div class="col-lg-8">
                                        <h6>Motivo</h6>
                                        <p class="text-muted">
                                            {{ essay?.resignation?.reason }}
                                        </p>
                                    </div>
                                    <div v-if="essay?.resignation?.document_name" class="col-lg-4">
                                        <h6>Justificante de renuncia (Anexo XI)</h6>
                                        <b-button variant="info" @click.prevent="downloadFiles(essay?.resignation?.document_name, essay?.folder_documents_id)"><i class="fas fa-file-download mr-2"></i> Descargar</b-button>
                                    </div>
                                    <div class="col-lg-12 mt-3">
                                        <h6>Fecha de renuncia</h6>
                                        <p class="text-muted">
                                            {{ essay?.resignation?.created_at }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="row p-3">
                                <div class="col-lg-12 text-center p-4">
                                    <p class="font-italic">No se ha solicitado ninguna renuncia</p>
                                    <b-button v-if="showElement([1,3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)" @click="addResignation()" variant="success"><i class="fa fa-plus"></i> Solicitar renuncia</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <!-- Tab finalizar proyecto -->
                <b-tab v-if="showElement([1,3], essay?.essay_status_id != 2)" title="Finalización" lazy>
                    <b-alert v-if="essay?.essay_status_id != 7 && essay?.essay_status_id != 4 && essay?.essay_status_id != 6" show>
                        <b-icon icon="exclamation-circle-fill" class="mr-2" variant="info"></b-icon> 
                        Para cumplimentar el informe final, es necesario enviar el formulario de encuesta <b><a href="javascript:void(0);" @click="swalSendMailForm()">aquí</a></b>. Recibirás los resultados por mail.<br>                        
                    </b-alert>
                    <div class="card mt-2">
                        <div class="card-body">
                            <!-- <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Finalización de proyecto</h5>                                    
                            </div> -->
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="card-title">Objetivos<i v-b-tooltip.hover title="Pulsa sobre los objetivos para realizar la valoración" class="far fa-question-circle font-size-12 ml-2"></i></h6>
                            </div>
                            <b-card no-body class="mb-1 shadow-none cursor-pointer mt-3" v-for="(target, i) in essay.targets" :key="i" @click="viewTarget(target)">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <h6 class="m-0 d-flex justify-content-between align-items-center">
                                    <div class="form-inline p-2">
                                    <span>{{ target.title }}</span>
                                    </div>
                                </h6>
                                </b-card-header>
                            </b-card>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Consecución de los objetivos del proyecto</h5>                                    
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <h6>Valoración de los objetivos alcanzados <span class="text-danger">*</span></h6>
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                        <p class="text-muted">
                                            {{ essay?.objetives_assessment }}
                                        </p>
                                    </div>
                                    <div v-else class="form-group">
                                        <textarea v-model="essay.objetives_assessment" class="form-control" rows="5" maxlength="5000" placeholder="" :class="{'is-invalid': $v.essay.objetives_assessment.$error}"></textarea>
                                        <small class="text-muted">{{ essay?.objetives_assessment?.length }}/5000</small>
                                        <div v-if="$v.essay.objetives_assessment.$error" class="invalid-feedback">
                                            <div v-if="!$v.essay.objetives_assessment.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <h6>Objetivos no alcanzados y justificación <span class="text-danger">*</span></h6>
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                        <p class="text-muted">
                                            {{ essay?.objetives_not_achieved }}
                                        </p>
                                    </div>
                                    <div v-else class="form-group">
                                        <textarea v-model="essay.objetives_not_achieved" class="form-control" rows="5" maxlength="5000" placeholder="" :class="{'is-invalid': $v.essay.objetives_not_achieved.$error}"></textarea>
                                        <small class="text-muted">{{ essay?.objetives_not_achieved?.length }}/5000</small>
                                        <div v-if="$v.essay.objetives_not_achieved.$error" class="invalid-feedback">
                                            <div v-if="!$v.essay.objetives_not_achieved.required">{{ errorMessages.required }}</div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <h6>Cambios realizados en el proyecto a lo largo de su puesta en marcha <span class="text-danger">*</span></h6>
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                        <p class="text-muted">
                                            {{ essay?.changes_project }}
                                        </p>
                                    </div>
                                    <div v-else class="form-group">
                                        <textarea v-model="essay.changes_project" class="form-control" rows="5" maxlength="5000" placeholder="" :class="{'is-invalid': $v.essay.changes_project.$error}"></textarea>
                                        <small class="text-muted">{{ essay?.changes_project?.length }}/5000</small>
                                        <div v-if="$v.essay.changes_project.$error" class="invalid-feedback">
                                            <div v-if="!$v.essay.changes_project.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Valoración global del proyecto</h5>                                    
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <h6>Valoración global del proyecto <span class="text-danger">*</span></h6>
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                        <p class="text-muted">
                                            {{ essay?.global_assessment }}
                                        </p>
                                    </div>
                                    <div v-else class="form-group">
                                        <textarea v-model="essay.global_assessment" class="form-control" rows="5" maxlength="5000" placeholder="" :class="{'is-invalid': $v.essay.global_assessment.$error}"></textarea>
                                        <small class="text-muted">{{ essay?.global_assessment?.length }}/5000</small>
                                        <div v-if="$v.essay.global_assessment.$error" class="invalid-feedback">
                                            <div v-if="!$v.essay.global_assessment.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <h6>Logros del proyecto <span class="text-danger">*</span></h6>
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                        <p class="text-muted">
                                            {{ essay?.project_achievements }}
                                        </p>
                                    </div>
                                    <div v-else class="form-group">
                                        <textarea v-model="essay.project_achievements" class="form-control" rows="5" maxlength="5000" placeholder="" :class="{'is-invalid': $v.essay.project_achievements.$error}"></textarea>
                                        <small class="text-muted">{{ essay?.project_achievements?.length }}/5000</small>
                                        <div v-if="$v.essay.project_achievements.$error" class="invalid-feedback">
                                            <div v-if="!$v.essay.project_achievements.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <h6>Valoración de indicadores <span class="text-danger">*</span></h6>
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                        <p class="text-muted">
                                            {{ essay?.indicators_assessment }}
                                        </p>
                                    </div>
                                    <div v-else class="form-group">
                                        <textarea v-model="essay.indicators_assessment" class="form-control" rows="5" maxlength="5000" placeholder="" :class="{'is-invalid': $v.essay.indicators_assessment.$error}"></textarea>
                                        <small class="text-muted">{{ essay?.indicators_assessment?.length }}/5000</small>
                                        <div v-if="$v.essay.indicators_assessment.$error" class="invalid-feedback">
                                            <div v-if="!$v.essay.indicators_assessment.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <h6>Incidencia en el centro y/o entidades participantes <span class="text-danger">*</span></h6>
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                        <p class="text-muted">
                                            {{ essay?.centers_incidents }}
                                        </p>
                                    </div>
                                    <div v-else class="form-group">
                                        <textarea v-model="essay.centers_incidents" class="form-control" rows="5" maxlength="5000" placeholder="" :class="{'is-invalid': $v.essay.centers_incidents.$error}"></textarea>
                                        <small class="text-muted">{{ essay?.centers_incidents?.length }}/5000</small>
                                        <div v-if="$v.essay.centers_incidents.$error" class="invalid-feedback">
                                            <div v-if="!$v.essay.centers_incidents.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Descripción de recursos elaborados <span class="text-danger">*</span></h5>                                    
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                        <p class="text-muted">
                                            {{ essay?.resources_description }}
                                        </p>
                                    </div>
                                    <div v-else class="form-group">
                                        <textarea v-model="essay.resources_description" class="form-control" rows="5" maxlength="5000" placeholder="" :class="{'is-invalid': $v.essay.resources_description.$error}"></textarea>
                                        <small class="text-muted">{{ essay?.resources_description?.length }}/5000</small>
                                        <div v-if="$v.essay.resources_description.$error" class="invalid-feedback">
                                            <div v-if="!$v.essay.resources_description.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Imagen más significativa del proyecto</h5>                                    
                            </div>
                            <div v-if="essay.finalising_project == 0" class="row mt-4">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <div class="custom-file">
                                            <!-- <input id="inputFileImage" type="file" name="file" class="custom-file-input" @change="onFileChange" :class="{'is-invalid': $v.essay.image_final.$error}"/> -->
                                            <input id="inputFileImage" type="file" name="file" class="custom-file-input" @change="onFileChange"/>
                                            <!-- <label class="custom-file-label" for="inputFileImage">{{ essay?.image_final || 'Seleccione un archivo' }}</label> -->
                                            <label class="custom-file-label" for="inputFileImage">{{ fileName }}</label>
                                            <!-- <div v-if="$v.essay.image_final.$error" class="invalid-feedback">
                                                <div v-if="!$v.essay.image_final.required">{{ errorMessages.required }}</div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center align-items-center" style="height: 500px;">
                                        <img v-if="imageAnexoIX" :src="imageAnexoIX" class="img-fluid" alt="" style="width: 350px; height: 350px;">
                                        <img v-else-if="essay.url_image_final" :src="essay.url_image_final" class="img-fluid" alt="" style="width: 350px; height: 350px;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start align-items-center">
                        <b-button v-if="essay?.finalising_project == 0 && essay?.essay_status_id != 7" class="mb-2 mr-2" @click="saveAssesment()" variant="success" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Guardar</b-button>
                        <b-button v-if="essay?.finalising_project == 0 && essay?.essay_status_id != 7" class="mb-2" @click="finishAssesment()" variant="primary" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Enviar finalización</b-button>
                        <b-button v-if="essay?.essay_status_id == 7" class="mb-2" @click="generateAnexoIX()" variant="primary" :disabled="loadingAnexoIX"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loadingAnexoIX"></span>Generar Anexo IX</b-button>
                    </div>
                </b-tab>
                <!-- Tab documentacion de proyecto -->
                <b-tab v-if="showElement([1], essay?.essay_status_id == 7)" title="Documentación" lazy>
                    <div class="d-flex justify-content-center">
                        <div class="card col-8">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h5 class="card-title">Generar excel de control económico</h5>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12 d-flex justify-content-center">

                                        <a
                                            href="javascript:void(0);"
                                            :class="['btn', 'btn-info', loading ? 'disabled' : '']"
                                            style="width: 100%; text-align: center; position: relative;"
                                            @click.prevent="exportUsers"
                                        >
                                            <template v-if="!loading">
                                                <i class="fas fa-file-excel mr-2"></i>Generar
                                            </template>
                                            <template v-else>
                                                <div class="progress-button">
                                                    <div class="spinner-border text-light" role="status" style="width: 1rem; height: 1rem;"></div>
                                                    Cargando...
                                                </div>
                                            </template>
                                        </a>

                                        <!-- <b-button :disabled="loading" variant="info" style="width: 100%;" @click="handleButtonClick">
                                            <span v-if="!loading"><i class="fas fa-file-excel mr-2"></i>Generar</span>
                                            <div v-else class="progress-button">
                                                <div class="spinner-border text-light" role="status" style="width: 1rem; height: 1rem;"></div>
                                                Cargando...
                                            </div>
                                        </b-button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
            
          </div>
        </div>
    </b-skeleton-wrapper>
    <!-- end row -->
  </Layout>
</template>